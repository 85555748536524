<template>
  <div class="grid min-h-dvh grid-rows-[min-content,auto,min-content] font-ubuntu text-base md:text-lg">
    <AppHeader
      :image="page.active_image"
    />
    <main
      role="main"
      class="relative max-md:bg-white"
    >
      <div class="container -mt-14 mb-20 flex gap-10 max-md:flex-col md:-mt-18 md:gap-20 lg:gap-40">
        <section class="flex flex-wrap gap-5 max-md:items-center md:flex-col md:gap-10">
          <Logo />
          <UiButton
            label="@koninlijkehorecanl"
            :to="settings.instagram"
          />
          <Socials class="max-md:hidden" />
        </section>
        <AppMenu
          :title="page.title"
          :links="quickLinks"
        />
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script setup lang="ts">
import {usePageQuery, useQuickLinksQuery} from '~/graphql/graphql';

const {settings} = useSofieSettings();
const {result: pageResult} = usePageQuery({
  segments: 'link-in-bio',
});

const {result: quickLinksResult} = useQuickLinksQuery();

const page = computed(() => pageResult.value?.page ?? {});
const quickLinks = computed(() => quickLinksResult.value?.quickLinks ?? []);

useSeoMeta({
  title: page.value.title,
});
</script>
