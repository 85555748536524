<template>
  <ul class="flex flex-col gap-8 text-xl text-primary">
    <template
      v-for="(item, index) in socialMenu"
      :key="index"
    >
      <li v-if="item.link">
        <NuxtLink
          class="group -m-1 flex w-fit items-center justify-center gap-x-2.5 p-1"
          target="_blank"
          :to="item.link"
          rel="nofollow noopener noreferrer"
        >
          <Icon
            class="transition-all group-hover:scale-125"
            :name="item.icon"
          />
          <div class="border-b border-b-transparent text-base transition-all group-hover:border-b-primary">
            {{ item.name }}
          </div>
        </NuxtLink>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
const {settings} = useSofieSettings();

const socialMenu = computed(() => ([
  {icon: 'mdi:instagram', link: settings.value.instagram, name: settings.value.instagram_name},
  {icon: 'mdi:facebook', link: settings.value.facebook, name: settings.value.facebook_name},
  {icon: 'prime:twitter', link: settings.value.twitter, name: settings.value.twitter_name},
  {icon: 'ri:linkedin-fill', link: settings.value.linkedin, name: settings.value.linkedin_name},
  {icon: 'mdi:youtube', link: settings.value.youtube, name: settings.value.youtube_name},
]));
</script>
