<template>
  <header>
    <NuxtPicture
      :src="props.image?.url"
      alt="KHN"
      :img-attrs="{class: 'h-56 lg:h-96 w-full object-cover object-center'}"
    />
  </header>
</template>

<script lang="ts" setup>
import type {Image} from '~/types/sofie';

const props = defineProps<{
  image?: Image,
}>();
</script>
