<template>
  <component
    :is="linkComponent"
    :to="isInternal ? to : undefined"
    :href="!isInternal ? to : undefined"
    :type="isButton ? type : undefined"
    class="group flex h-12 w-fit items-center justify-center gap-2 rounded-full border border-gray bg-white px-3 text-primary transition-all duration-300 hover:text-secondary"
    :target="isExternal ? '_blank' : undefined"
    :rel="isExternal ? 'noopener noreferrer' : undefined"
  >
    <span
      class="flex gap-2"
      :class="order"
    >
      <slot>
        {{ label }}
      </slot>
    </span>
    <Icon
      v-if="iconName"
      :name="iconName"
    />
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

const props = withDefaults(defineProps<{
  to?: RouteLocationRaw | string
  label?: string
  type?: string,
  iconPosition?: 'left' | 'right'
  iconName?: string,
}>(), {
  to: undefined,
  label: '',
  type: 'button',
  iconPosition: 'right',
  iconName: undefined,
});

const isButton = computed(() => !props.to);
const isInternal = computed(() => typeof props.to === 'string' && props.to.startsWith('/'));
const isExternal = computed(() => typeof props.to === 'string' && !props.to.startsWith('/'));

const linkComponent = computed(() => {
  if (isInternal.value) {
    return 'router-link';
  } else if (isExternal.value) {
    return 'a';
  } else {
    return 'button';
  }
});

const order = computed(() => {
  return props.iconPosition === 'left' ? 'order-1' : '';
});
</script>
