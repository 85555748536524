import type {File, Image} from '~/types/sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number | string; output: number | string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  File: { input: File; output: File; }
  Iban: { input: string; output: string; }
  Image: { input: Image; output: Image; }
  JSON: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  Null: { input: null; output: null; }
  PrivateFile: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Uuid: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  geodata?: Maybe<Scalars['JSON']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  house_number_suffix?: Maybe<Scalars['String']['output']>;
  house_number_with_suffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  township?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressHint = {
  __typename?: 'AddressHint';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  house_number_suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  township?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type Address_4a5e94cfccca7136b327f417d0d16e84 = {
  __typename?: 'Address_4a5e94cfccca7136b327f417d0d16e84';
  city?: Maybe<Scalars['String']['output']>;
};

export type Address_6fc068124c8f4fceb625f3454fa39082 = {
  __typename?: 'Address_6fc068124c8f4fceb625f3454fa39082';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  house_number_with_suffix?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type Address_F02121771dd1d4283bbb4b319c056fa6 = {
  __typename?: 'Address_f02121771dd1d4283bbb4b319c056fa6';
  city?: Maybe<Scalars['String']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  house_number_suffix?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AfgenomenLedenvoordeel = {
  __typename?: 'AfgenomenLedenvoordeel';
  annotations: Array<Annotation>;
  annotations_count?: Maybe<Scalars['Int']['output']>;
  begindatum?: Maybe<Scalars['Date']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  einddatum?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  member_benefit?: Maybe<Ledenvoordeel>;
  opportunity?: Maybe<Verkoopkans>;
  organisation?: Maybe<Bedrijf>;
  published: Scalars['Boolean']['output'];
  saving?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type AfgenomenLedenvoordeel_7c8d684e8c9d051e82380be55f8f76ab = {
  __typename?: 'AfgenomenLedenvoordeel_7c8d684e8c9d051e82380be55f8f76ab';
  begindatum?: Maybe<Scalars['Date']['output']>;
  einddatum?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  member_benefit?: Maybe<Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  opportunity?: Maybe<Verkoopkans_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  organisation?: Maybe<Bedrijf_A5ab557b4ff99c82468521c35aad774f>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type AfgenomenLedenvoordeel_7c8d684e8c9d051e82380be55f8f76abPagination = {
  __typename?: 'AfgenomenLedenvoordeel_7c8d684e8c9d051e82380be55f8f76abPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<AfgenomenLedenvoordeel_7c8d684e8c9d051e82380be55f8f76ab>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type AfgenomenLedenvoordeel_37888e8988535662964699642f862420 = {
  __typename?: 'AfgenomenLedenvoordeel_37888e8988535662964699642f862420';
  annotations: Array<Annotation_Bc56019c134ba20566b87cb58b5b7ab1>;
  begindatum?: Maybe<Scalars['Date']['output']>;
  einddatum?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  member_benefit?: Maybe<Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  opportunity?: Maybe<Verkoopkans_D9d4334aafccaa9a7625b4aa1e8c219c>;
  organisation?: Maybe<Bedrijf_B721b60b2e8aa118ccb0fad51e9cdcba>;
  title: Scalars['String']['output'];
  user?: Maybe<User_4f414b1110b6b271139ff5fafb9c2048>;
};

export type AfgenomenLedenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8 = {
  __typename?: 'AfgenomenLedenvoordeel_c4f53c471e86c5d1bbd94e2ec1fd98a8';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type AfgenomenLedenvoordeel_D4c9196a22f29d3d9c494757339a8a05 = {
  __typename?: 'AfgenomenLedenvoordeel_d4c9196a22f29d3d9c494757339a8a05';
  begindatum?: Maybe<Scalars['Date']['output']>;
  einddatum?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  member_benefit?: Maybe<Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  opportunity?: Maybe<Verkoopkans_D9d4334aafccaa9a7625b4aa1e8c219c>;
  organisation?: Maybe<Bedrijf_01f3328e970b6b230ce61dfd664d9923>;
  title: Scalars['String']['output'];
  user?: Maybe<User_4f414b1110b6b271139ff5fafb9c2048>;
};

export type Annotation = {
  __typename?: 'Annotation';
  attachment: Array<Scalars['PrivateFile']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Annotation_Bc764cfef94c6f35aac365fa6a56f527 = {
  __typename?: 'Annotation_bc764cfef94c6f35aac365fa6a56f527';
  attachment: Array<Scalars['PrivateFile']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Annotation_Bc56019c134ba20566b87cb58b5b7ab1 = {
  __typename?: 'Annotation_bc56019c134ba20566b87cb58b5b7ab1';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Bedrijf = {
  __typename?: 'Bedrijf';
  account_level?: Maybe<Scalars['String']['output']>;
  account_number?: Maybe<Scalars['String']['output']>;
  account_type?: Maybe<Scalars['String']['output']>;
  active_membership?: Maybe<Lidmaatschap_F3a93daba862e68a02d7fc86721bf1e6>;
  billing_address?: Maybe<Address>;
  billing_email?: Maybe<Scalars['Email']['output']>;
  business_type?: Maybe<Bedrijfstype>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  facade_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoice_name?: Maybe<Scalars['String']['output']>;
  is_ledenvoordeel_partner: Scalars['Boolean']['output'];
  is_member: Scalars['Boolean']['output'];
  kvk_location_number?: Maybe<Scalars['String']['output']>;
  kvk_number?: Maybe<Scalars['String']['output']>;
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  legal_entity?: Maybe<Scalars['String']['output']>;
  mail_address?: Maybe<Address>;
  memberships: Array<Lidmaatschap>;
  mobile_number?: Maybe<Scalars['String']['output']>;
  number_of_employees?: Maybe<Scalars['Int']['output']>;
  number_of_rooms?: Maybe<Scalars['Int']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  physical_address?: Maybe<Address>;
  published: Scalars['Boolean']['output'];
  region?: Maybe<Scalars['String']['output']>;
  region_manager?: Maybe<User>;
  sbi_code?: Maybe<Scalars['String']['output']>;
  sbi_code_description?: Maybe<Scalars['String']['output']>;
  surface_area?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<Array<Maybe<User_58375274d89c61db34ee595d43dbb5ba>>>;
  users_count?: Maybe<Scalars['Int']['output']>;
  vat_group?: Maybe<BtwGroep>;
  vat_number?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Bedrijf_01f3328e970b6b230ce61dfd664d9923 = {
  __typename?: 'Bedrijf_01f3328e970b6b230ce61dfd664d9923';
  account_number?: Maybe<Scalars['String']['output']>;
  facade_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  kvk_location_number?: Maybe<Scalars['String']['output']>;
  kvk_number?: Maybe<Scalars['String']['output']>;
  memberships: Array<Lidmaatschap_37f389529ed2af7b48c099487e2a8a58>;
  physical_address?: Maybe<Address_F02121771dd1d4283bbb4b319c056fa6>;
  title: Scalars['String']['output'];
};

export type Bedrijf_30de4e4c040b8f19ebf583cc0bce33df = {
  __typename?: 'Bedrijf_30de4e4c040b8f19ebf583cc0bce33df';
  facade_name?: Maybe<Scalars['String']['output']>;
  physical_address?: Maybe<Address_6fc068124c8f4fceb625f3454fa39082>;
  title: Scalars['String']['output'];
};

export type Bedrijf_2592d003bfbe42eb1f16be0d32a8650c = {
  __typename?: 'Bedrijf_2592d003bfbe42eb1f16be0d32a8650c';
  account_number?: Maybe<Scalars['String']['output']>;
  facade_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  physical_address?: Maybe<Address_F02121771dd1d4283bbb4b319c056fa6>;
};

export type Bedrijf_A5ab557b4ff99c82468521c35aad774f = {
  __typename?: 'Bedrijf_a5ab557b4ff99c82468521c35aad774f';
  account_number?: Maybe<Scalars['String']['output']>;
  active_membership?: Maybe<Lidmaatschap_37f389529ed2af7b48c099487e2a8a58>;
  facade_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Bedrijf_Afa275eb8541ed80a570c2574ff187c0 = {
  __typename?: 'Bedrijf_afa275eb8541ed80a570c2574ff187c0';
  account_number?: Maybe<Scalars['String']['output']>;
  facade_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Bedrijf_B721b60b2e8aa118ccb0fad51e9cdcba = {
  __typename?: 'Bedrijf_b721b60b2e8aa118ccb0fad51e9cdcba';
  account_number?: Maybe<Scalars['String']['output']>;
  account_type?: Maybe<Scalars['String']['output']>;
  active_membership?: Maybe<Lidmaatschap_37f389529ed2af7b48c099487e2a8a58>;
  facade_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  kvk_location_number?: Maybe<Scalars['String']['output']>;
  kvk_number?: Maybe<Scalars['String']['output']>;
  physical_address?: Maybe<Address_F02121771dd1d4283bbb4b319c056fa6>;
  region_manager?: Maybe<User_86a5084f5551b9d39677bb9833f78a86>;
  title: Scalars['String']['output'];
};

export type Bedrijf_C4f53c471e86c5d1bbd94e2ec1fd98a8 = {
  __typename?: 'Bedrijf_c4f53c471e86c5d1bbd94e2ec1fd98a8';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Bedrijf_D041347b4a395efcd4919f15db447a3b = {
  __typename?: 'Bedrijf_d041347b4a395efcd4919f15db447a3b';
  facade_name?: Maybe<Scalars['String']['output']>;
  physical_address?: Maybe<Address_4a5e94cfccca7136b327f417d0d16e84>;
  title: Scalars['String']['output'];
};

export type Bedrijf_Ffe02693b2fe3be4f65d8bf54037fbf8 = {
  __typename?: 'Bedrijf_ffe02693b2fe3be4f65d8bf54037fbf8';
  account_number?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Bedrijfstype = {
  __typename?: 'Bedrijfstype';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type BtwCode = {
  __typename?: 'BtwCode';
  bc_code?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type BtwGroep = {
  __typename?: 'BtwGroep';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  groupcode?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type BtwType = {
  __typename?: 'BtwType';
  capabilities: Array<Capability>;
  code?: Maybe<BtwCode>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  group?: Maybe<BtwGroep>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  ancestors: Array<Category>;
  capabilities: Array<Capability>;
  children: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  descendants: Array<Category>;
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  published: Scalars['Boolean']['output'];
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type CategoryActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Category_556fad62d1f885a3c11a22350125ab74 = {
  __typename?: 'Category_556fad62d1f885a3c11a22350125ab74';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type DateRangeFilterInput = {
  end?: InputMaybe<Scalars['Date']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
};

export type Document = {
  __typename?: 'Document';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document: Array<Scalars['PrivateFile']['output']>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
  verschenen_in_nieuwsbrief?: Maybe<Scalars['Boolean']['output']>;
  voor_bestuurders?: Maybe<Scalars['Boolean']['output']>;
};


export type DocumentActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type DocumentImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Evenement = {
  __typename?: 'Evenement';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  address?: Maybe<Address>;
  availability_status: EventAvailabilityStatusEnum;
  capabilities: Array<Capability>;
  confirmed?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['DateTime']['output']>;
  confirmed_by?: Maybe<User>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  event_type_template?: Maybe<Scalars['String']['output']>;
  external_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  location?: Maybe<Bedrijf>;
  location_address?: Maybe<Address>;
  location_name?: Maybe<Scalars['String']['output']>;
  maximum_participants?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  products?: Maybe<Array<Maybe<EvenementProduct>>>;
  publiek?: Maybe<Scalars['Boolean']['output']>;
  published: Scalars['Boolean']['output'];
  query_fields?: Maybe<Scalars['JSON']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  signup_closed?: Maybe<Scalars['Boolean']['output']>;
  signup_deadline?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  voor_bestuurders?: Maybe<Scalars['Boolean']['output']>;
  voor_leden?: Maybe<Scalars['Boolean']['output']>;
};


export type EvenementActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type EvenementImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type EvenementBlok = {
  __typename?: 'EvenementBlok';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  event: Evenement;
  id: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type EvenementProduct = {
  __typename?: 'EvenementProduct';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  event: Evenement;
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  price_category?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type EvenementProduct_5e9add2ee76f5d1db53ab8bb1be4a247 = {
  __typename?: 'EvenementProduct_5e9add2ee76f5d1db53ab8bb1be4a247';
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  price_category?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Evenement_7da2b347e9989a77aa132aa97b4f28f6 = {
  __typename?: 'Evenement_7da2b347e9989a77aa132aa97b4f28f6';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  address?: Maybe<Address_6fc068124c8f4fceb625f3454fa39082>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  event_type_template?: Maybe<Scalars['String']['output']>;
  external_url?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Bedrijf_30de4e4c040b8f19ebf583cc0bce33df>;
  location_name?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<EvenementProduct_5e9add2ee76f5d1db53ab8bb1be4a247>>>;
  query_fields?: Maybe<Scalars['JSON']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  signup_closed?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  voor_bestuurders?: Maybe<Scalars['Boolean']['output']>;
};


export type Evenement_7da2b347e9989a77aa132aa97b4f28f6Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Evenement_5497c06a9142045ae6b3172b22005587 = {
  __typename?: 'Evenement_5497c06a9142045ae6b3172b22005587';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  address?: Maybe<Address_6fc068124c8f4fceb625f3454fa39082>;
  availability_status: EventAvailabilityStatusEnum;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Bedrijf_D041347b4a395efcd4919f15db447a3b>;
  location_name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  signup_closed?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};


export type Evenement_5497c06a9142045ae6b3172b22005587Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export enum EventAvailabilityStatusEnum {
  Available = 'available',
  FullyBooked = 'fully_booked'
}

export type EventCompanyInfoInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  house_number_addition?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kvk_number?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type EventParticipantInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  insertion?: InputMaybe<Scalars['String']['input']>;
  last_name: Scalars['String']['input'];
};

export type Factuur = {
  __typename?: 'Factuur';
  automatic_debit?: Maybe<Scalars['String']['output']>;
  balance: Scalars['Float']['output'];
  capabilities: Array<Capability>;
  company?: Maybe<Bedrijf>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  debitor?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  invoice_number?: Maybe<Scalars['String']['output']>;
  lines?: Maybe<Array<Maybe<FactuurRegel>>>;
  order?: Maybe<WebshopOrder>;
  published: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  total_price: Scalars['Float']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type FactuurPagination = {
  __typename?: 'FactuurPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Factuur>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type FactuurRegel = {
  __typename?: 'FactuurRegel';
  amount?: Maybe<Scalars['Int']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  invoice: Factuur;
  price?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<WebshopProduct>;
  published: Scalars['Boolean']['output'];
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Form = {
  __typename?: 'Form';
  fields: Array<FormField>;
  id: Scalars['Int']['output'];
  schema: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique?: Maybe<Scalars['Boolean']['output']>;
};

export enum GenderEnum {
  Man = 'man',
  Overige = 'overige',
  Vrouw = 'vrouw'
}

export type GlobalSearch = {
  __typename?: 'GlobalSearch';
  benefits: Array<Ledenvoordeel>;
  documents: Array<Document>;
  events: Array<Evenement>;
};

export enum GlobalSearchSubjectEnum {
  All = 'all',
  Benefits = 'benefits',
  Documents = 'documents',
  Events = 'events'
}

export type Inschrijving = {
  __typename?: 'Inschrijving';
  archive_reason?: Maybe<Scalars['String']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  capabilities: Array<Capability>;
  company: Bedrijf;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  evenement: Evenement;
  gebruiker: User;
  id: Scalars['Int']['output'];
  in_queue?: Maybe<Scalars['Boolean']['output']>;
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type KvkBaseProfile = {
  __typename?: 'KvkBaseProfile';
  address?: Maybe<Address>;
  company_name?: Maybe<Scalars['String']['output']>;
  legal_form?: Maybe<Scalars['String']['output']>;
  sbi_activities?: Maybe<Array<SbiActivity>>;
};

export type KvkCompanyInput = {
  account_number?: InputMaybe<Scalars['String']['input']>;
  kvk_location_number?: InputMaybe<Scalars['String']['input']>;
  kvk_number?: InputMaybe<Scalars['String']['input']>;
  legal_entity?: InputMaybe<Scalars['String']['input']>;
  sbi_activities?: InputMaybe<Array<SbiActivityInput>>;
};

export type KvkResult = {
  __typename?: 'KvkResult';
  branch_number?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  kvk_number?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Ledenvoordeel = {
  __typename?: 'Ledenvoordeel';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  external_url?: Maybe<Scalars['String']['output']>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  open_opportunities?: Maybe<Array<Maybe<Bedrijf>>>;
  partner?: Maybe<Bedrijf>;
  published: Scalars['Boolean']['output'];
  redeemed_organisations?: Maybe<Array<Maybe<Bedrijf>>>;
  savings?: Maybe<Scalars['Float']['output']>;
  short_content?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  usps?: Maybe<Scalars['String']['output']>;
};


export type LedenvoordeelActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type LedenvoordeelImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8 = {
  __typename?: 'Ledenvoordeel_c4f53c471e86c5d1bbd94e2ec1fd98a8';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Lidmaatschap = {
  __typename?: 'Lidmaatschap';
  cancellation_date?: Maybe<Scalars['Date']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  invoice?: Maybe<Factuur>;
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  lines: Array<LidmaatschapRegel>;
  mandate?: Maybe<Mandaat>;
  /** Alle partners van actieve ledenvoordelen hebben een melding ontvangen bij beëindiging van het lidmaatschap */
  notified_partners?: Maybe<Scalars['Boolean']['output']>;
  organisation?: Maybe<Bedrijf>;
  published: Scalars['Boolean']['output'];
  start_date?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LidmaatschapRegel = {
  __typename?: 'LidmaatschapRegel';
  amount?: Maybe<Scalars['Float']['output']>;
  cancellation_date?: Maybe<Scalars['Date']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  lidmaatschap: Lidmaatschap;
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<WebshopProduct>;
  published: Scalars['Boolean']['output'];
  start_date?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
  total_amount?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type LidmaatschapRegel_3966fbacc1950e92f18c078558867fbd = {
  __typename?: 'LidmaatschapRegel_3966fbacc1950e92f18c078558867fbd';
  amount?: Maybe<Scalars['Float']['output']>;
  cancellation_date?: Maybe<Scalars['Date']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  start_date?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
};

export type Lidmaatschap_013b8202251d48b3740eecb94b098154 = {
  __typename?: 'Lidmaatschap_013b8202251d48b3740eecb94b098154';
  cancellation_date?: Maybe<Scalars['Date']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  mandate?: Maybe<Mandaat_67b342f8d2b3c446d8eed3d1655dbd05>;
  organisation?: Maybe<Bedrijf_Ffe02693b2fe3be4f65d8bf54037fbf8>;
  start_date?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
};

export type Lidmaatschap_37f389529ed2af7b48c099487e2a8a58 = {
  __typename?: 'Lidmaatschap_37f389529ed2af7b48c099487e2a8a58';
  end_date?: Maybe<Scalars['Date']['output']>;
  start_date?: Maybe<Scalars['Date']['output']>;
};

export type Lidmaatschap_F3a93daba862e68a02d7fc86721bf1e6 = {
  __typename?: 'Lidmaatschap_f3a93daba862e68a02d7fc86721bf1e6';
  cancellation_date?: Maybe<Scalars['Date']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  start_date?: Maybe<Scalars['Date']['output']>;
};

export type Lidmaatschap_F489e02c25ff211eb21378a19adeeccd = {
  __typename?: 'Lidmaatschap_f489e02c25ff211eb21378a19adeeccd';
  cancellation_date?: Maybe<Scalars['Date']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  mandate?: Maybe<Mandaat_4d927517801ec5066c6b157276534d14>;
  organisation?: Maybe<Bedrijf_Ffe02693b2fe3be4f65d8bf54037fbf8>;
  start_date?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum LocaleEnum {
  Nl = 'nl'
}

export type Locatie = {
  __typename?: 'Locatie';
  capabilities: Array<Capability>;
  company?: Maybe<Bedrijf>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  parking_lots?: Maybe<Scalars['Int']['output']>;
  published: Scalars['Boolean']['output'];
  seats?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  special_mentions?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Mandaat = {
  __typename?: 'Mandaat';
  bankaccount?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  signature_date?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Mandaat_4d927517801ec5066c6b157276534d14 = {
  __typename?: 'Mandaat_4d927517801ec5066c6b157276534d14';
  id: Scalars['Int']['output'];
  term?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Mandaat_67b342f8d2b3c446d8eed3d1655dbd05 = {
  __typename?: 'Mandaat_67b342f8d2b3c446d8eed3d1655dbd05';
  bankaccount?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  signature_date?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type MandateDetails = {
  __typename?: 'MandateDetails';
  bankAccount: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  frequency: Scalars['Int']['output'];
  mandateId: Scalars['ID']['output'];
  mandateSignatureDate: Scalars['String']['output'];
  mandateStatus: Scalars['String']['output'];
  mandateType: Scalars['String']['output'];
};

export enum MandateTermEnum {
  OneTerm = 'one_term',
  SixTerms = 'six_terms'
}

export type MembershipInput = {
  employee_count?: InputMaybe<Scalars['Int']['input']>;
  invoice_email: Scalars['Email']['input'];
  invoice_name: Scalars['String']['input'];
  /** This field is subject to change when membership plans get implemented */
  plan: Scalars['JSON']['input'];
  room_count?: InputMaybe<Scalars['Int']['input']>;
  surface?: InputMaybe<Scalars['Int']['input']>;
};

export type MembershipProduct = {
  __typename?: 'MembershipProduct';
  code?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Menu = {
  __typename?: 'Menu';
  items?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  children?: Maybe<Array<Maybe<MenuItem>>>;
  scope?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Scalars['Boolean']['output']>;
  activateMemberBenefit?: Maybe<Scalars['Boolean']['output']>;
  changePassword?: Maybe<Scalars['Null']['output']>;
  createMandateInvitation?: Maybe<Scalars['String']['output']>;
  createOpportunity?: Maybe<Scalars['Boolean']['output']>;
  createOrder?: Maybe<OrderResult>;
  disableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  eventSignup: Scalars['Boolean']['output'];
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  login?: Maybe<Scalars['String']['output']>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  mailInvoice?: Maybe<Scalars['Boolean']['output']>;
  requestPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  restartWebshopPayment?: Maybe<OrderResult>;
  sendUserInvite?: Maybe<Scalars['Boolean']['output']>;
  startNewMembership?: Maybe<NewMembershipResponse>;
  storeNewsletterPreferences?: Maybe<Scalars['Null']['output']>;
  submitForm?: Maybe<Scalars['Null']['output']>;
  unsubscribeFromNewsletter?: Maybe<Scalars['Null']['output']>;
  updateCommunicationPreferences?: Maybe<Scalars['Boolean']['output']>;
  updateCompanyAddress?: Maybe<Scalars['Boolean']['output']>;
  updateCompanyDetails?: Maybe<Scalars['Boolean']['output']>;
  updateOpportunity?: Maybe<Verkoopkans_24e8ac11239a4a2b3261a493939bf4c8>;
  updateSettledMemberBenefit?: Maybe<AfgenomenLedenvoordeel_D4c9196a22f29d3d9c494757339a8a05>;
  updateUser?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAcceptInviteArgs = {
  email: Scalars['Email']['input'];
  invite: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationActivateMemberBenefitArgs = {
  benefit_id: Scalars['Int']['input'];
  companies?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationChangePasswordArgs = {
  current_password: Scalars['String']['input'];
  new_password: Scalars['String']['input'];
  new_password_confirm: Scalars['String']['input'];
};


export type MutationCreateMandateInvitationArgs = {
  membership: Scalars['ID']['input'];
};


export type MutationCreateOpportunityArgs = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  benefit: Scalars['String']['input'];
  company_id: Scalars['ID']['input'];
  contact: OpportunityContactInput;
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOrderArgs = {
  billing_address: AddressInput;
  company?: InputMaybe<Scalars['Int']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  middle_name?: InputMaybe<Scalars['String']['input']>;
  products: Array<ProductInput>;
  shipping_address?: InputMaybe<AddressInput>;
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String']['input'];
};


export type MutationEventSignupArgs = {
  cancellation_conditions: Scalars['Boolean']['input'];
  company_info: EventCompanyInfoInput;
  participants: Array<EventParticipantInput>;
  privacy_policy: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  two_factor_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMailInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRestartWebshopPaymentArgs = {
  order_id: Scalars['Uuid']['input'];
};


export type MutationSendUserInviteArgs = {
  email: Scalars['Email']['input'];
  uuid: Scalars['Uuid']['input'];
};


export type MutationStartNewMembershipArgs = {
  kvk?: InputMaybe<KvkCompanyInput>;
  membership: MembershipInput;
  user_information?: InputMaybe<UserInformationInput>;
};


export type MutationStoreNewsletterPreferencesArgs = {
  department: Array<Scalars['String']['input']>;
  region: Array<Scalars['String']['input']>;
};


export type MutationSubmitFormArgs = {
  form_data: Scalars['String']['input'];
  form_id: Scalars['ID']['input'];
  g_recaptcha_response?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnsubscribeFromNewsletterArgs = {
  email: Scalars['Email']['input'];
  token: Scalars['String']['input'];
};


export type MutationUpdateCommunicationPreferencesArgs = {
  communication_preferences: Array<Scalars['String']['input']>;
};


export type MutationUpdateCompanyAddressArgs = {
  billing_address: AddressInput;
  billing_email?: InputMaybe<Scalars['Email']['input']>;
  id: Scalars['ID']['input'];
  invoice_name?: InputMaybe<Scalars['String']['input']>;
  mail_address?: InputMaybe<AddressInput>;
  physical_address?: InputMaybe<AddressInput>;
};


export type MutationUpdateCompanyDetailsArgs = {
  email?: InputMaybe<Scalars['Email']['input']>;
  id: Scalars['ID']['input'];
  mobile_number?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  vat_number?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOpportunityArgs = {
  benefit_amount: Scalars['Int']['input'];
  benefit_period: Scalars['String']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  partner_status: Scalars['String']['input'];
  reason_for_negative_status?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationUpdateSettledMemberBenefitArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  address: AddressInput;
  date_of_birth?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['Email']['input'];
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  initials?: InputMaybe<Scalars['String']['input']>;
  last_name: Scalars['String']['input'];
  middle_name?: InputMaybe<Scalars['String']['input']>;
  mobile_number?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type NewMembershipResponse = {
  __typename?: 'NewMembershipResponse';
  invitation_url?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type NewsletterPreferenceOption = {
  __typename?: 'NewsletterPreferenceOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type NewsletterPreferences = {
  __typename?: 'NewsletterPreferences';
  departments: Array<NewsletterPreferenceOption>;
  regions: Array<NewsletterPreferenceOption>;
};

export type OpportunityContactInput = {
  email: Scalars['Email']['input'];
  name: Scalars['String']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  order?: Maybe<WebshopOrder>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Page = {
  __typename?: 'Page';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  children?: Maybe<Array<Maybe<Page>>>;
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  template?: Maybe<Template>;
  template_name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type PageActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type PageImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  addressAutofill: AddressHint;
  adminEvents: Array<Evenement>;
  bedrijfLedenvoordelen?: Maybe<Array<Maybe<AfgenomenLedenvoordeel>>>;
  businessTypes: Array<Bedrijfstype>;
  calculateMembershipProducts: Array<MembershipProduct>;
  checkKvk: Array<KvkResult>;
  checkLedenvoordeelStatus?: Maybe<Scalars['Boolean']['output']>;
  checkRegistrationEmail?: Maybe<Scalars['Boolean']['output']>;
  communicationPreferences: Array<Scalars['String']['output']>;
  companies?: Maybe<Array<Maybe<Bedrijf>>>;
  company?: Maybe<Bedrijf>;
  currentTenantInfo?: Maybe<Tenant_3af8041b6556776eda1312e7c8acaed7>;
  documentSubjects?: Maybe<Array<Maybe<Category_556fad62d1f885a3c11a22350125ab74>>>;
  documents: Array<Document>;
  event?: Maybe<Evenement_7da2b347e9989a77aa132aa97b4f28f6>;
  eventRegions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  eventTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  events?: Maybe<Array<Maybe<Evenement_5497c06a9142045ae6b3172b22005587>>>;
  form?: Maybe<Form>;
  globalSearch?: Maybe<GlobalSearch>;
  invoice?: Maybe<Factuur>;
  invoices?: Maybe<FactuurPagination>;
  kvkBaseProfile: KvkBaseProfile;
  lastAdminEvent?: Maybe<Evenement>;
  lastDocuments?: Maybe<Array<Maybe<Document>>>;
  lastInvoices?: Maybe<Array<Maybe<Factuur>>>;
  ledenvoordeel?: Maybe<Ledenvoordeel>;
  ledenvoordelen?: Maybe<Array<Maybe<Ledenvoordeel>>>;
  mandateDetails?: Maybe<MandateDetails>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  membership?: Maybe<Lidmaatschap_013b8202251d48b3740eecb94b098154>;
  membershipLines?: Maybe<Array<Maybe<LidmaatschapRegel_3966fbacc1950e92f18c078558867fbd>>>;
  memberships?: Maybe<Array<Maybe<Lidmaatschap_F489e02c25ff211eb21378a19adeeccd>>>;
  menu?: Maybe<Menu>;
  menus?: Maybe<Array<Maybe<Menu>>>;
  newsletterPreferenceOptions: NewsletterPreferences;
  nextEvent?: Maybe<Evenement>;
  order?: Maybe<WebshopOrder>;
  page?: Maybe<Page>;
  quickLinks: Array<QuickLink>;
  registrations?: Maybe<Array<Maybe<Inschrijving>>>;
  searchKvkByAddress: Array<KvkResult>;
  settings: Array<Setting>;
  settledMemberBenefit?: Maybe<AfgenomenLedenvoordeel_37888e8988535662964699642f862420>;
  settledMemberBenefits?: Maybe<AfgenomenLedenvoordeel_7c8d684e8c9d051e82380be55f8f76abPagination>;
  verkoopkans?: Maybe<Verkoopkans_69ab0a30e438575314ab13c4cab79331>;
  verkoopkansen?: Maybe<Verkoopkans_60860985d06c2d300f547ca150fa88ccPagination>;
  verkoopkansenForCompany: Array<Verkoopkans>;
  webshopCategories: Array<WebshopCategorie>;
  webshopCategory?: Maybe<WebshopCategorie>;
  webshopProduct: WebshopProduct;
  webshopProducts?: Maybe<Array<Maybe<WebshopProduct>>>;
};


export type QueryAddressAutofillArgs = {
  house_number: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};


export type QueryAdminEventsArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBedrijfLedenvoordelenArgs = {
  company?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCalculateMembershipProductsArgs = {
  employee_count: Scalars['Int']['input'];
  not_a_catering_company?: InputMaybe<Scalars['Boolean']['input']>;
  room_count: Scalars['Int']['input'];
  surface: Scalars['Int']['input'];
};


export type QueryCheckKvkArgs = {
  kvk_number: Scalars['String']['input'];
};


export type QueryCheckLedenvoordeelStatusArgs = {
  benefit_id: Scalars['Int']['input'];
  company_id: Scalars['Int']['input'];
};


export type QueryCheckRegistrationEmailArgs = {
  email: Scalars['Email']['input'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentSubjectsArgs = {
  for_manager?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDocumentsArgs = {
  for_manager?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryEventArgs = {
  slug: Scalars['String']['input'];
};


export type QueryEventRegionsArgs = {
  manager?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEventTypesArgs = {
  manager?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEventsArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGlobalSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<GlobalSearchSubjectEnum>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryKvkBaseProfileArgs = {
  branch_number?: InputMaybe<Scalars['String']['input']>;
  kvk_number: Scalars['String']['input'];
};


export type QueryLastDocumentsArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLastInvoicesArgs = {
  amount?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLedenvoordeelArgs = {
  slug: Scalars['String']['input'];
};


export type QueryLedenvoordelenArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMandateDetailsArgs = {
  membershipId: Scalars['ID']['input'];
};


export type QueryMembershipArgs = {
  membership: Scalars['ID']['input'];
};


export type QueryMembershipLinesArgs = {
  membership: Scalars['ID']['input'];
};


export type QueryMembershipsArgs = {
  company?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMenuArgs = {
  name: Scalars['String']['input'];
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryOrderArgs = {
  order_id: Scalars['Uuid']['input'];
};


export type QueryPageArgs = {
  segments?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchKvkByAddressArgs = {
  house_number: Scalars['String']['input'];
  house_number_addition?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};


export type QuerySettledMemberBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySettledMemberBenefitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerkoopkansArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVerkoopkansenArgs = {
  date?: InputMaybe<DateRangeFilterInput>;
  finished?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVerkoopkansenForCompanyArgs = {
  company?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryWebshopCategoryArgs = {
  slug: Scalars['String']['input'];
};


export type QueryWebshopProductArgs = {
  category?: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type QueryWebshopProductsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuickLink = {
  __typename?: 'QuickLink';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SbiActivity = {
  __typename?: 'SbiActivity';
  sbiCode: Scalars['String']['output'];
  sbiOmschrijving: Scalars['String']['output'];
};

export type SbiActivityInput = {
  sbiCode: Scalars['String']['input'];
  sbiOmschrijving: Scalars['String']['input'];
};

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_follow?: Maybe<Scalars['Boolean']['output']>;
  meta_index?: Maybe<Scalars['Boolean']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type Setting = {
  __typename?: 'Setting';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Mixed']['output']>;
};

export type Template = TemplateLedendocument | TemplateStandaard;

export type TemplateLedendocument = {
  __typename?: 'TemplateLedendocument';
  capabilities: Array<Capability>;
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  document: Array<Scalars['PrivateFile']['output']>;
  id: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  locales: Array<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type TenantActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type TenantImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Tenant_3af8041b6556776eda1312e7c8acaed7 = {
  __typename?: 'Tenant_3af8041b6556776eda1312e7c8acaed7';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
};


export type Tenant_3af8041b6556776eda1312e7c8acaed7Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String']['output'];
  email: Scalars['Email']['output'];
  secret: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  name: Scalars['String']['output'];
};

export type UserInformationInput = {
  address?: InputMaybe<AddressInput>;
  business_type: Scalars['String']['input'];
  company_email?: InputMaybe<Scalars['Email']['input']>;
  company_mobile_number?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  company_phone_number?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  facade_name?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderEnum>;
  has_kvk?: InputMaybe<Scalars['Boolean']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  middle_name?: InputMaybe<Scalars['String']['input']>;
  mobile_number?: InputMaybe<Scalars['String']['input']>;
  music_rights?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  sbi_activities?: InputMaybe<Array<SbiActivityInput>>;
  vat_number?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  address?: Maybe<Address>;
  avatar: Scalars['String']['output'];
  capabilities: Array<Capability>;
  communication_preferences: Array<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  date_of_birth?: Maybe<Scalars['Date']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  dynamics_portal_contact_id?: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  first_name: Scalars['String']['output'];
  gender?: Maybe<GenderEnum>;
  has_active_membership: Scalars['Boolean']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  invoices?: Maybe<Array<Maybe<Factuur>>>;
  is_employee: Scalars['Boolean']['output'];
  is_manager?: Maybe<Scalars['Boolean']['output']>;
  last_invoice?: Maybe<Factuur>;
  last_name: Scalars['String']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  mobile_number?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newsletter_department?: Maybe<Scalars['String']['output']>;
  newsletter_region?: Maybe<Scalars['String']['output']>;
  organisations?: Maybe<Array<Maybe<Bedrijf>>>;
  organisations_count?: Maybe<Scalars['Int']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  role?: Maybe<Role>;
  role_id: Scalars['ID']['output'];
  tenants: Array<Tenant>;
  tenants_count?: Maybe<Scalars['Int']['output']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};


export type User_07e75d37dfdb1fad1b23e74888c041b4Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4ImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type User_4f414b1110b6b271139ff5fafb9c2048 = {
  __typename?: 'User_4f414b1110b6b271139ff5fafb9c2048';
  email: Scalars['Email']['output'];
  mobile_number?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
};

export type User_86a5084f5551b9d39677bb9833f78a86 = {
  __typename?: 'User_86a5084f5551b9d39677bb9833f78a86';
  email: Scalars['Email']['output'];
  name: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
};

export type User_206d7b66f5521f049eaefba8f8f5aa24 = {
  __typename?: 'User_206d7b66f5521f049eaefba8f8f5aa24';
  email: Scalars['Email']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type User_58375274d89c61db34ee595d43dbb5ba = {
  __typename?: 'User_58375274d89c61db34ee595d43dbb5ba';
  email: Scalars['Email']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  middle_name?: Maybe<Scalars['String']['output']>;
  mobile_number?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type Verkoopkans = {
  __typename?: 'Verkoopkans';
  account?: Maybe<Bedrijf>;
  account_comment?: Maybe<Scalars['String']['output']>;
  addition?: Maybe<Scalars['String']['output']>;
  annotations: Array<Annotation>;
  annotations_count?: Maybe<Scalars['Int']['output']>;
  benefit: Ledenvoordeel;
  benefit_amount?: Maybe<Scalars['Float']['output']>;
  benefit_period?: Maybe<Scalars['String']['output']>;
  bpf_traversed_path?: Maybe<Scalars['JSON']['output']>;
  bpf_workflowinstance?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  comments?: Maybe<Scalars['JSON']['output']>;
  contact?: Maybe<User>;
  contact_email?: Maybe<Scalars['Email']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  end_date_reason?: Maybe<Scalars['String']['output']>;
  has_priority?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  is_member?: Maybe<Scalars['Boolean']['output']>;
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  partner: Bedrijf;
  partner_status?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  reason_for_negative_status?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  request_date?: Maybe<Scalars['DateTime']['output']>;
  settled_memberbenefit?: Maybe<AfgenomenLedenvoordeel>;
  show_to_partner?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<VerkoopkansBronEnum>;
  source_completion?: Maybe<Scalars['String']['output']>;
  source_employee?: Maybe<User>;
  start_date?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum VerkoopkansBronEnum {
  BenefitPartner = 'benefit_partner',
  Buying = 'buying',
  Campaign = 'campaign',
  General = 'general',
  Member = 'member',
  Mijnkhn = 'mijnkhn',
  RegionManager = 'region_manager',
  Website = 'website'
}

export enum VerkoopkansStateEnum {
  Lost = 'lost',
  Open = 'open',
  Won = 'won'
}

export enum VerkoopkansStatusEnum {
  InProgress = 'in_progress',
  Negative = 'negative',
  New = 'new',
  Positive = 'positive'
}

export type Verkoopkans_24e8ac11239a4a2b3261a493939bf4c8 = {
  __typename?: 'Verkoopkans_24e8ac11239a4a2b3261a493939bf4c8';
  account?: Maybe<Bedrijf_2592d003bfbe42eb1f16be0d32a8650c>;
  addition?: Maybe<Scalars['String']['output']>;
  annotations: Array<Annotation_Bc56019c134ba20566b87cb58b5b7ab1>;
  benefit?: Maybe<Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  benefit_amount?: Maybe<Scalars['Float']['output']>;
  benefit_period?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['JSON']['output']>;
  contact?: Maybe<User_4f414b1110b6b271139ff5fafb9c2048>;
  description?: Maybe<Scalars['String']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  end_date_reason?: Maybe<Scalars['String']['output']>;
  has_priority?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  is_member?: Maybe<Scalars['Boolean']['output']>;
  partner?: Maybe<Bedrijf_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  partner_status?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  request_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<VerkoopkansBronEnum>;
  start_date?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Verkoopkans_69ab0a30e438575314ab13c4cab79331 = {
  __typename?: 'Verkoopkans_69ab0a30e438575314ab13c4cab79331';
  account?: Maybe<Bedrijf_B721b60b2e8aa118ccb0fad51e9cdcba>;
  addition?: Maybe<Scalars['String']['output']>;
  annotations: Array<Annotation_Bc764cfef94c6f35aac365fa6a56f527>;
  benefit?: Maybe<Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  benefit_amount?: Maybe<Scalars['Float']['output']>;
  benefit_period?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['JSON']['output']>;
  contact?: Maybe<User_4f414b1110b6b271139ff5fafb9c2048>;
  contact_email?: Maybe<Scalars['Email']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  end_date_reason?: Maybe<Scalars['String']['output']>;
  has_priority?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  is_member?: Maybe<Scalars['Boolean']['output']>;
  partner?: Maybe<Bedrijf_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  partner_status?: Maybe<Scalars['String']['output']>;
  reason_for_negative_status?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  request_date?: Maybe<Scalars['DateTime']['output']>;
  settled_memberbenefit?: Maybe<AfgenomenLedenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  source?: Maybe<VerkoopkansBronEnum>;
  source_completion?: Maybe<Scalars['String']['output']>;
  source_employee?: Maybe<User_206d7b66f5521f049eaefba8f8f5aa24>;
  start_date?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Verkoopkans_60860985d06c2d300f547ca150fa88cc = {
  __typename?: 'Verkoopkans_60860985d06c2d300f547ca150fa88cc';
  account?: Maybe<Bedrijf_Afa275eb8541ed80a570c2574ff187c0>;
  account_comment?: Maybe<Scalars['String']['output']>;
  benefit?: Maybe<Ledenvoordeel_C4f53c471e86c5d1bbd94e2ec1fd98a8>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  partner_status?: Maybe<Scalars['String']['output']>;
  request_date?: Maybe<Scalars['DateTime']['output']>;
  start_date?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Verkoopkans_60860985d06c2d300f547ca150fa88ccPagination = {
  __typename?: 'Verkoopkans_60860985d06c2d300f547ca150fa88ccPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Verkoopkans_60860985d06c2d300f547ca150fa88cc>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Verkoopkans_C4f53c471e86c5d1bbd94e2ec1fd98a8 = {
  __typename?: 'Verkoopkans_c4f53c471e86c5d1bbd94e2ec1fd98a8';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type Verkoopkans_D9d4334aafccaa9a7625b4aa1e8c219c = {
  __typename?: 'Verkoopkans_d9d4334aafccaa9a7625b4aa1e8c219c';
  addition?: Maybe<Scalars['String']['output']>;
  benefit_amount?: Maybe<Scalars['Float']['output']>;
  benefit_period?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['JSON']['output']>;
  contact_email?: Maybe<Scalars['Email']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date_reason?: Maybe<Scalars['String']['output']>;
  has_priority?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  is_member?: Maybe<Scalars['Boolean']['output']>;
  partner_status?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  request_date?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<VerkoopkansBronEnum>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type WebshopCategorie = {
  __typename?: 'WebshopCategorie';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};


export type WebshopCategorieActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type WebshopCategorieImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type WebshopOrder = {
  __typename?: 'WebshopOrder';
  bank?: Maybe<Scalars['String']['output']>;
  billing_address?: Maybe<Address>;
  capabilities: Array<Capability>;
  company?: Maybe<Bedrijf>;
  company_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_invoice_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  order_items?: Maybe<Array<Maybe<WebshopOrderItem>>>;
  payment_method?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  shipping_address?: Maybe<Address>;
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  total_price?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  uuid: Scalars['Uuid']['output'];
};

export type WebshopOrderItem = {
  __typename?: 'WebshopOrderItem';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  amount: Scalars['Int']['output'];
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  membership_discount: Scalars['Float']['output'];
  order: WebshopOrder;
  price: Scalars['Float']['output'];
  price_with_discount_and_vat?: Maybe<Scalars['Float']['output']>;
  price_with_vat?: Maybe<Scalars['Float']['output']>;
  price_without_vat?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<WebshopProduct>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  total_price?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vat: Scalars['Float']['output'];
};


export type WebshopOrderItemActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type WebshopOrderItemImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type WebshopPrijslijst = {
  __typename?: 'WebshopPrijslijst';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  start_date?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type WebshopPrijslijstitem = {
  __typename?: 'WebshopPrijslijstitem';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dynamics_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_sync?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  pricelist?: Maybe<WebshopPrijslijst>;
  product?: Maybe<WebshopProduct>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type WebshopProduct = {
  __typename?: 'WebshopProduct';
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  active_image?: Maybe<Scalars['Image']['output']>;
  btw?: Maybe<BtwCode>;
  capabilities: Array<Capability>;
  category?: Maybe<WebshopCategorie>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamics_order_product_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Check Glide docs for argument options: https://glide.thephpleague.com/1.0/api/quick-reference/ */
  images: Array<Scalars['Image']['output']>;
  membership_discount: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  price_with_vat?: Maybe<Scalars['Float']['output']>;
  price_without_vat?: Maybe<Scalars['Float']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};


export type WebshopProductActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type WebshopProductImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type PageQueryVariables = Exact<{
  segments: Scalars['String']['input'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', url: string, title: string, slug: string, active_image?: Image | null, images: Array<Image>, seo?: { __typename?: 'Seo', meta_title?: string | null, meta_description?: string | null, meta_index?: boolean | null, meta_follow?: boolean | null } | null, template?: { __typename?: 'TemplateLedendocument' } | { __typename?: 'TemplateStandaard', text?: string | null } | null } | null };

export type QuickLinksQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickLinksQuery = { __typename?: 'Query', quickLinks: Array<{ __typename?: 'QuickLink', id: number, title: string, url: string }> };


export const PageDocument = gql`
    query page($segments: String!) {
  page(segments: $segments) {
    url
    title
    slug
    active_image
    images
    seo {
      meta_title
      meta_description
      meta_index
      meta_follow
    }
    template {
      ... on TemplateStandaard {
        text: tekst
      }
    }
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables?: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;
export const QuickLinksDocument = gql`
    query quickLinks @cache {
  quickLinks {
    id
    title
    url
  }
}
    `;

/**
 * __useQuickLinksQuery__
 *
 * To run a query within a Vue component, call `useQuickLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickLinksQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useQuickLinksQuery();
 */
export function useQuickLinksQuery(options: VueApolloComposable.UseQueryOptions<QuickLinksQuery, QuickLinksQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuickLinksQuery, QuickLinksQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuickLinksQuery, QuickLinksQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<QuickLinksQuery, QuickLinksQueryVariables>(QuickLinksDocument, {}, options);
}
export function useQuickLinksLazyQuery(options: VueApolloComposable.UseQueryOptions<QuickLinksQuery, QuickLinksQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuickLinksQuery, QuickLinksQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuickLinksQuery, QuickLinksQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<QuickLinksQuery, QuickLinksQueryVariables>(QuickLinksDocument, {}, options);
}
export type QuickLinksQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<QuickLinksQuery, QuickLinksQueryVariables>;